/* @font-face {
    font-family: 'Gill Facia MT Std';
    src: url('./fonts/GillFaciaMTStd-RegDisplay.woff2') format('woff2'), url('./fonts/GillFaciaMTStd-RegDisplay.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gill L';
    src: url('./fonts/GillSans-Light.woff2') format('woff2'), url('./fonts/GillSans-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
} */

* {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    outline: none;    
}

::selection { background: #eeeeee; }